import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TemplateTypeService } from '../../../services/template-type.service'
import { Template } from 'src/app/models/template';
import { TemplateService } from 'src/app/services/template.service';


@Component({
  selector: 'app-create-template-dialog',
  templateUrl: './create-template-dialog.component.html',
  styleUrls: ['./create-template-dialog.component.scss']
})
export class CreateTemplateDialogComponent implements OnInit {
  types: any;
  selectedType: any;
  templateName: string;
  template: Template;

  constructor(public dialogRef: MatDialogRef<CreateTemplateDialogComponent>,
    private templateTypeService: TemplateTypeService,
    private templateService: TemplateService
  ) { }

  ngOnInit() {
    this.template = new Template();
    this.getTypes();
  }

  onConfirm(): void {
    this.template.name = this.templateName;
    this.template.status = "draft";
    this.template.html = "";
    this.template.header_html = "";
    this.template.footer_html = "";
    this.template.template_type_id = this.selectedType;

    this.templateService.saveTemplate(this.template).subscribe(
        res => {
          this.dialogRef.close(res.id);
        },
        error => {
        })
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  getTypes() {
    this.templateTypeService.getAll().subscribe((res) => {
      this.types = res.rows;
    });
  }

}
