import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private api = "global";

  constructor(
    private http:HttpClient
  ) {}

  getGlobalHeader() {
    return this.http.get<any>(`${environment.docgenApiUrl}/${this.api}/header`)
      .pipe(map(data => {
        return data;
      }))
  }

  saveGlobalHeader(data: any) {
    return this.http.post<any>(`${environment.docgenApiUrl}/${this.api}/header`, {data: data})
      .pipe(map(temp => {
        return temp;
      }))
  }

  getGlobalFooter() {
    return this.http.get<any>(`${environment.docgenApiUrl}/${this.api}/footer`)
      .pipe(map(data => {
        return data;
      }))
  }

  saveGlobalFooter(data: any) {
    return this.http.post<any>(`${environment.docgenApiUrl}/${this.api}/footer`, {data: data})
      .pipe(map(temp => {
        return temp;
      }))
  }

}
