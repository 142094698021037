import { Routes } from '@angular/router';
import { AppAuthGuard } from '../../app.authguard';

//COMPONENTES
import { TemplateTypeOverviewComponent } from './template-type-overview/template-type-overview.component';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { TemplateOverviewComponent } from './template-overview/template-overview.component';
import { GlobalSettingsComponent } from './global-settins/global-settings.component';

export const DocumentTemplateRoutes: Routes = [
    {
        path: "",
        // component:SideBarComponent,
        children: [
            {
                path: "template-editor",
                component: TemplateEditorComponent,
                // canActivate: [AppAuthGuard],
            },
            {
                path: "template-editor/:id",
                component: TemplateEditorComponent,
                // canActivate: [AppAuthGuard],
            },
            {
                path: "image",
                component: TemplateEditorComponent,
                // canActivate: [AppAuthGuard],

            },
            {
                path: "template-type",
                component: TemplateTypeOverviewComponent,
                // canActivate: [AppAuthGuard],
            },
            {
                path: "template-overview",
                component: TemplateOverviewComponent,
                // canActivate: [AppAuthGuard],
            },
            {
                path: "global-settings",
                component: GlobalSettingsComponent,
                // canActivate: [AppAuthGuard],
            }
        ],
    },
];
