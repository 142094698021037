import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DocumentTemplateRoutes } from './document-template.routing';
import { TemplateTypeOverviewComponent } from './template-type-overview/template-type-overview.component';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { GlobalSettingsComponent } from './global-settins/global-settings.component';
import { HeaderEditorComponent } from './header-editor/header-editor.component';
import { FooterEditorComponent } from './footer-editor/footer-editor.component';
import { TemplateOverviewComponent } from './template-overview/template-overview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableViewComponent } from "../../shared/components/table-view/table-view.component"
import { TreeViewComponent } from "../../shared/components/tree-view/tree-view.component"
import { ConfirmDialogComponent } from "../../shared/components/confirm-dialog/confirm-dialog.component"
import { CreateTemplateDialogComponent } from "../document-template/create-template-dialog/create-template-dialog.component"
import { CreateTemplateTypeDialogComponent } from "../document-template/create-template-type-dialog/create-template-type-dialog.component"
import { PageSetupDialogComponent } from "../document-template/page-setup-dialog/page-setup-dialog.component"

import { MatTreeModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatDialogModule, MatCardModule, MatButtonModule, MatTooltipModule, MatCheckboxModule, MatSlideToggleModule } from '@angular/material';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ConvertTimezonePipe } from 'src/app/pipes/convert-timezone.pipe';

// COMPONENTS
@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTreeModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSortModule,
    MatIconModule,
    MatSlideToggleModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    FormsModule,
    AngularEditorModule,
    ReactiveFormsModule,
    MatCardModule,
    RouterModule.forChild(DocumentTemplateRoutes),
    TranslateModule.forChild({
      isolate: false
    }),
    MatTableModule
  ],
  declarations: [
    TemplateTypeOverviewComponent,
    TemplateEditorComponent,
    GlobalSettingsComponent,
    HeaderEditorComponent,
    FooterEditorComponent,
    TemplateOverviewComponent,
    TableViewComponent,
    TreeViewComponent,
    ConfirmDialogComponent,
    CreateTemplateDialogComponent,
    CreateTemplateTypeDialogComponent,
    PageSetupDialogComponent,
    ConvertTimezonePipe
  ],
  exports: [

    ConvertTimezonePipe
  ],
  entryComponents: [
    ConfirmDialogComponent, CreateTemplateDialogComponent, CreateTemplateTypeDialogComponent, PageSetupDialogComponent
  ],
})
export class DocumentTemplateModule { }
