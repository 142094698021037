import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TableViewService {
  constructor(private http: HttpClient) {}

  getData(api, pageIndex, pagSize, sortColumn, sortDirection, search) {
    return this.http.get<any>(
      `${environment.docgenApiUrl}/` +
        api +
        `?page=${pageIndex}&limit=${pagSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${search}`
    );
  }
}
