import { TenantConfigurationService } from "src/app/services/tenant-configuration.service";
import { KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';

export function initializeKeycloak(
  keycloak: KeycloakService,
  configService: TenantConfigurationService,
) {
  return async () => {
    const configuration = await configService.getKeycloakConfiguration();

    let keycloakConfig: KeycloakConfig = {
      url: configuration.keycloakHost,
      realm: configuration.keycloakRealmName,
      clientId: 'c4p-employee-portal',
    };

    return keycloak.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      bearerExcludedUrls: [],
    });
  };
}
