import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  @Output() isCollapsed = new EventEmitter<boolean>();
  @Input() isCollapsedOrExpand = false;

  constructor() { }

  ngOnInit() {
  }

  collapseOrExpand() {
    this.isCollapsedOrExpand = !this.isCollapsedOrExpand;
    this.isCollapsed.next(this.isCollapsedOrExpand);
  }
}
