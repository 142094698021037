import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TenantConfiguration } from "../models/tenant-configuration.model";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class TenantConfigurationService {
  private url = `${environment.tenantManagementApiUrl}`;

  constructor(private httpClient: HttpClient) {}

  public async getKeycloakConfiguration(): Promise<TenantConfiguration> {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const subdomain = urlParams.get("subdomain");
    localStorage.setItem("currentRole", urlParams.get("currentRole"));
    localStorage.setItem("timezone", urlParams.get("timezone"));

    // var re = /icom./gi;
    // const subdomain = window.location.origin.replace(re, "");

    return await new Promise((resolve, reject) => {
      this.httpClient
        .get(`${this.url}/configuration?subdomain=${subdomain}`)
        .subscribe((response: TenantConfiguration) => {
          resolve(response);
        });
    });
  }
}
